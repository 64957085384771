<template>
  <transition name="modal" v-if="isVisible" >
    <div class="modal-mask" @keydown.esc="close">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header title">
            <div class=" row align--center mb-1">
              <div class="flex sm10">
                <slot name="header">
                  {{title}}
                </slot>
              </div>
              <div class="flex xs12 sm2">
                <div class="d-flex justify--end">
                  <va-button class="text-right" v-on:click="close" outline small icon="ion-md-close ion"/>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-body">
            <slot name="body">
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  props: ['show', 'title'],
  data () {
    return {
    }
  },
  mounted () {
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) {
        this.close()
      }
    })
  },
  computed: {
    isVisible () {
      return this.show.toString() === 'true'
    },
  },
  methods: {
    close () {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style lang="css">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: fit-content;
  border-radius: 0.375rem;
  box-shadow: 0 4px 70px -18px #707070;
  max-height: calc(100vh - 2rem);
}

.modal-container {
  min-width: 300px;
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
