<template>
  <div class="dashboard">
    <modal :show.sync="ajuizamento.add" :title="'Informar negativação de '+ajuizamento.razaoSocial">
      <template slot="body">
        <va-input
          v-model="ajuizamento.numeroProcesso"
          label="Número do processo (opcional)"
        />

        <va-input
          v-model="ajuizamento.link"
          label="Link para acompanhamento do processo (opcional)"
        />

        <va-input
          v-model="ajuizamento.comarca"
          label="Comarca (opcional)"
        />

        <va-input
          v-model="ajuizamento.vara"
          label="Vara (opcional)"
        />

      </template>

      <template slot="footer">
        <va-button
          outline
          color="success"
          v-on:click="salvarAjuizamento"
        >
          Gravar
        </va-button>

      </template>
    </modal>
    <va-card :title="$t('ajuizamento.ajuizados.title') + ` - ${filteredData.length} operações encontradas`">
      <div class="row align--center mb-1">
        <div class="flex xs12 sm6">
          <va-input
            class="ma-0"
            :value="term"
            :placeholder="$t('tables.searchByName')"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-select
            :options="perPageOptions"
            v-model="perPage"
            label="Itens por página"
          />
        </div>
      </div>

      <va-data-table
        :per-page="perPage"
        :fields="mode ? detailedFields : fields"
        :data="filteredData"
        :loading="loading"
        hoverable
      >
        <template slot="razaoSocial" slot-scope="props">
          <a :href="`https://zxvf.tutudigital.com.br/tomadores/${props.rowData.tomadorId}/dossie1`" target="_blank">
            {{props.rowData.razaoSocial}}
          </a>
        </template>
        <template slot="numeroProcesso" slot-scope="props">
          <a v-on:click="addNegativacao(props.rowData)" title="Informar número processo" alt="Informar número processo" v-if="!props.rowData.numeroProcesso" href="javascript://">
            <i class="va-icon vuestic-iconset vuestic-iconset-auth"></i>
          </a>
          <span v-if="props.rowData.numeroProcesso"> {{props.rowData.numeroProcesso}} </span>
        </template>
        <template slot="linkAcompanhamentoProcesso" slot-scope="props">
          <a v-on:click="addNegativacao(props.rowData)" title="Informar link processo" alt="Informar link processo" v-if="!props.rowData.linkAcompanhamentoProcesso" href="javascript://">
            <i class="va-icon vuestic-iconset vuestic-iconset-auth"></i>
          </a>
          <span v-if="props.rowData.linkAcompanhamentoProcesso"> {{props.rowData.linkAcompanhamentoProcesso}} </span>
        </template>
        <template slot="vara" slot-scope="props">
          <a v-on:click="addNegativacao(props.rowData)" title="Informar vara" alt="Informar vara" v-if="!props.rowData.vara" href="javascript://">
            <i class="va-icon vuestic-iconset vuestic-iconset-auth"></i>
          </a>
          <span v-if="props.rowData.vara"> {{props.rowData.vara}} </span>
        </template>
        <template slot="comarca" slot-scope="props">
          <a v-on:click="addNegativacao(props.rowData)" title="Informar vara" alt="Informar comarca" v-if="!props.rowData.comarca" href="javascript://">
            <i class="va-icon vuestic-iconset vuestic-iconset-auth"></i>
          </a>
          <span v-if="props.rowData.comarca"> {{props.rowData.comarca}} </span>
        </template>
        <template slot="alterar" slot-scope="props">
          <a v-on:click="addNegativacao(props.rowData)" title="Alterar dados" alt="Alterar dados" href="javascript://">
            <i class="va-icon vuestic-iconset vuestic-iconset-auth"></i>
          </a>
        </template>
        <template slot="acoes" slot-scope="props">
          <a v-on:click="valorPresente(props.rowData)" title="Valor Presente" alt="Valor Presente" href="javascript://">
            V.P
          </a>
          | <a v-on:click="fichaCadastral(props.rowData)" title="Ficha Cadastral" alt="Ficha Cadastral" href="javascript://">
            F.C
          </a>
          | <a v-on:click="baixarCCBs(props.rowData)" title="CCBs" alt="CCBs" href="javascript://">
            CCBs
          </a>
          |
          <a v-on:click="baixarContratosInvestidores(props.rowData)" title="Contratos Investidores" alt="Contratos Investidores" href="javascript://">
            T.C
          </a>
          |
          <a v-on:click="baixarIrpfSocios(props.rowData)" title="IRPF Sócios" alt="IRPF Sócios" href="javascript://">
            IRPF
          </a>
        </template>
      </va-data-table>
    </va-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import negativacoesService from '../../services/Cobranca/NegativacoesService'
import Modal from '../modal/Modal'
import operacoesService from '../../services/Cobranca/OperacoesService'

export default {
  components: {
    Modal,
  },
  data () {
    return {
      operacoes: [],
      perPage: 15,
      perPageOptions: ['15', '25', '35', '50', '75', '100', '200', '300', '400', '500'],
      loading: false,
      term: null,
      mode: 0,
      ajuizamento: {
        add: false,
        emprestimoId: null,
        razaoSocial: null,
        numeroProcesso: null,
        link: null,
        comarca: null,
        vara: null,
      },
    }
  },
  computed: {
    fields () {
      return [{
        name: 'id',
        title: 'EmprestimoId',
      }, {
        name: '__slot:razaoSocial',
        title: 'Razão Social',
      }, {
        name: 'acordoId',
        title: 'AcordoId',
      },
      {
        name: 'statusAcordo',
        title: 'Status Acordo',
      },
      {
        name: 'diasVencidoPrimeiraParcela',
        title: 'Dias Vencido',
      },
      {
        name: 'negativado',
        title: 'CNPJ Negativado',
      },
      {
        name: 'avalistasNegativados',
        title: 'Avalistas Negativado',
      },
      {
        name: 'enviadoParaJuridico',
        title: 'Enviado para Jurídico',
      }, //
      {
        name: '__slot:numeroProcesso',
        title: 'Número Processo',
      }, {
        name: '__slot:linkAcompanhamentoProcesso',
        title: 'Link',
      }, {
        name: '__slot:vara',
        title: 'Vara',
      }, {
        name: '__slot:comarca',
        title: 'Comarca',
      }, {
        name: '__slot:alterar',
        title: 'Alterar',
      },
      {
        name: '__slot:acoes',
        title: 'Ações',
      }]
    },
    modeOptions () {
      return [{
        value: 0,
        label: this.$t('dashboard.table.brief'),
      }]
    },
    filteredData () {
      if (!this.term || this.term.length < 1) {
        return this.operacoes
      }

      return this.operacoes.filter(item => {
        return item.razaoSocial.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },
  methods: {
    async fichaCadastral (item) {
      const res = await operacoesService.fichaCadastral(item.id)

      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `${item.id} - ${item.razaoSocial} - Ficha Cadastral.pdf`) // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    async baixarIrpfSocios (item) {
      const res = await operacoesService.baixarIrpfSocios(item.id)

      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `${item.id} - ${item.razaoSocial} - IRPF Sócios.zip`) // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    async baixarCCBs (item) {
      const res = await operacoesService.baixarCCBs(item.id)

      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `${item.id} - ${item.razaoSocial} - CCBs.zip`) // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    async baixarContratosInvestidores (item) {
      const res = await operacoesService.baixarContratosInvestidores(item.id)

      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `${item.id} - ${item.razaoSocial} - Contratos Investidores.zip`) // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    async valorPresente (item) {
      window.Swal.fire({
        title: 'Informe a data para cálculo atualizado',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        inputValue: new Date().toLocaleString().substring(0, 10),
        showCancelButton: true,
        confirmButtonText: 'Gerar PDF',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !window.Swal.isLoading(),
      }).then(async (result) => {
        console.log(result)
        if (result.isConfirmed) {
          const res = await operacoesService.valorPresente(item.id, result.value)

          const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute('download', `${item.id} - ${item.razaoSocial} - Valor Presente.pdf`) // any other extension
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
      })
    },
    async salvarAjuizamento () {
      (await negativacoesService.incluirNumeroProcesso({
        emprestimoId: this.ajuizamento.emprestimoId,
        numeroProcesso: this.ajuizamento.numeroProcesso,
        linkProcesso: this.ajuizamento.link,
        vara: this.ajuizamento.vara,
        comarca: this.ajuizamento.comarca,
      }))

      this.operacoes = (await negativacoesService.ajuizados()).data.data
      this.ajuizamento.add = false
    },
    addNegativacao (item) {
      this.ajuizamento.emprestimoId = item.id
      this.ajuizamento.razaoSocial = item.razaoSocial
      this.ajuizamento.add = true
      this.ajuizamento.link = item.linkAcompanhamentoProcesso
      this.ajuizamento.numeroProcesso = item.numeroProcesso
      this.ajuizamento.vara = item.vara
      this.ajuizamento.comarca = item.comarca
    },
    getStatusColor (status) {
      if (status === 'paid') {
        return 'success'
      }

      if (status === 'processing') {
        return 'info'
      }

      return 'danger'
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
  async mounted () {
    this.operacoes = (await negativacoesService.ajuizados()).data.data
  },
}
</script>
